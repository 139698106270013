<template>
    <div>
        <v-app>
            <!-- Edit Address Popup Start -->
            <v-dialog v-model="editAddressPopup" persistent max-width="500">
                <v-card>
                    <v-card-title>
                        <div class="d-flex justify-space-between align-center full-width-button">
                            <p class="ma-0 body-heading font-weight-semi-bold">Edit Address</p>

                            <v-btn icon v-on:click.stop="editAddressPopupClose()" :disabled="editAddressClickedFlag"><img src="https://s3iconimages.mymedicine.com.mm/cancelButton.svg" alt="close-icon"></v-btn>
                        </div>

                    </v-card-title>

                    <v-divider></v-divider>

                    <div v-if="editAddressPopupLoader">
                        <v-progress-circular size="20" indeterminate color="primary"></v-progress-circular>
                    </div>
                    <div v-else>
                        <div class="pa-4">
                            <!-- Deliver To & Mobile Number -->
                            <div class="py-2 d-flex flex-column">
                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text" align="left">Deliver To*</p>
                                
                                <v-text-field solo flat placeholder="Enter Name" v-model="editAddressData.delivered_to_name" hide-details="auto" class="ma-0 pa-0 body-text light-grey-border"
                                    @click="" @keyup.enter="" @click:append=""
                                ></v-text-field>
                            </div>

                            <div class="py-2 d-flex flex-column">
                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text" align="left">Mobile Number*</p>
                                
                                <v-text-field solo flat placeholder="Enter Mobile Number" v-model="editAddressData.mobile_number" hide-details="auto" class="ma-0 pa-0 body-text light-grey-border"
                                    @click="" @keyup.enter="" @click:append=""
                                ></v-text-field>
                            </div>

                            <!-- House no -->
                            <div class="py-2">
                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text" align="left">House Number / Building Number</p>

                                <v-text-field solo flat placeholder="Enter Address Line" v-model="editAddressData.house_number" hide-details="auto" class="ma-0 pa-0 body-text light-grey-border"
                                    @click="" @keyup.enter="" @click:append=""
                                ></v-text-field>
                            </div>

                            <!-- Street Name / Locality -->
                            <div class="py-2 d-flex flex-column">
                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text" align="left">Street Name / Locality*</p>
                                
                                <v-text-field solo flat placeholder="Enter Street Name" v-model="editAddressData.street_address" hide-details="auto" class="ma-0 pa-0 body-text light-grey-border"
                                    @click="" @keyup.enter="" @click:append=""
                                ></v-text-field>
                            </div>

                            <!-- Landmark -->
                            <div class="py-2">
                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text" align="left">Landmark</p>

                                <v-text-field solo flat placeholder="Enter Landmark" v-model="editAddressData.landmark" hide-details="auto" class="ma-0 pa-0 body-text light-grey-border"
                                    @click="" @keyup.enter="" @click:append=""
                                ></v-text-field>
                            </div>

                            <!-- State -->
                            <div class="py-2">
                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text" align="left">State</p>
                                <v-select placeholder="Select State - Township" class="ma-0 body-text rounded-lg light-grey-border state-segment" solo flat
                                    :items="addressStateList"
                                    item-text="name" item-value="id"
                                    v-model="editAddressData.state"
                                    return-object
                                >
                                </v-select>
                            </div>

                            <!-- Address Type -->
                            <div>
                                <div class="">
                                    <p class="ma-0 body-heading font-weight-regular light-grey-color-text" align="left">Address Type</p>
                                </div>

                                <div class="py-2 d-flex justify-space-between align-center flex-wrap">
                                    <div class="pr-2 one-third-width">
                                        <button class="mr-2 px-2 py-2 d-flex justify-center align-center rounded full-width-button" :class="[editAddressData.address_type_name != null && editAddressData.address_type_name == 'Home' ? 'light-blue-border light-blue-color-text' : 'light-grey-border dark-grey-color-text']" @click="editAddressData.address_type_name='Home'">
                                            <img :src="editAddressData.address_type_name != null && editAddressData.address_type_name == 'Home' ? 'https://s3iconimages.mymedicine.com.mm/AddressHomeIcon_LightBlue.svg' : 'https://s3iconimages.mymedicine.com.mm/AddressHomeIcon.svg'" alt="home-icon">
                                            <p class="ma-0 pl-2 body-text font-weight-medium">Home</p>
                                        </button>
                                    </div>
                                    
                                    <div class="px-2 one-third-width">
                                        <button class="mr-2 px-2 py-2 d-flex justify-center align-center rounded full-width-button" :class="[editAddressData.address_type_name != null && editAddressData.address_type_name == 'Work' ? 'light-blue-border light-blue-color-text' : 'light-grey-border dark-grey-color-text']" @click="editAddressData.address_type_name='Work'">
                                            <img :src="editAddressData.address_type_name != null && editAddressData.address_type_name == 'Work' ? 'https://s3iconimages.mymedicine.com.mm/AddressWorkIcon_LightBlue.svg' : 'https://s3iconimages.mymedicine.com.mm/AddressWorkIcon.svg'" alt="home-icon">
                                            <p class="ma-0 pl-2 body-text font-weight-medium">Work</p>
                                        </button>
                                    </div>

                                    <div class="pl-2 one-third-width">
                                        <button class="mr-2 px-2 py-2 d-flex justify-center align-center rounded full-width-button" :class="[editAddressData.address_type_name != null && editAddressData.address_type_name != 'Home' && editAddressData.address_type_name != 'Work' ? 'light-blue-border light-blue-color-text' : 'light-grey-border dark-grey-color-text']" @click="editAddressData.address_type_name='Other'">
                                            <p class="ma-0 pl-2 body-text font-weight-medium">Other</p>
                                        </button>
                                    </div>
                                </div>

                                <div v-if="editAddressData.address_type_name != 'Home' || editAddressData.address_type_name != 'Work'" class="py-2">
                                    <v-text-field solo flat placeholder="Enter a Name" v-model="editAddressData.address_type_name" hide-details="auto" class="ma-0 pa-0 body-text light-grey-border"
                                        @click="" @keyup.enter="" @click:append=""
                                    ></v-text-field>
                                </div>
                            </div>
                        </div>

                        <v-card-actions class="ma-0 pa-0">
                            <div class="px-4 pb-4 full-width-button d-flex justify-center align-center">
                                <div>
                                    <div v-if="!editAddressClickedFlag">
                                        <button class="mt-2 px-4 py-2 rounded light-blue-background edit-address-action-button" @click="editAddress()"><span class="px-4 py-2 body-text white-color-text font-weight-semi-bold">Edit</span></button>
                                    </div>
                                    <div v-else>
                                        <button class="mt-2 px-4 py-2 rounded light-blue-background edit-address-action-button">
                                            <v-progress-circular size="20" indeterminate color="white"></v-progress-circular>
                                        </button>
                                    </div>
                                </div>

                                <div class="pl-4">
                                    <button class="mt-2 px-4 py-2 rounded light-blue-background edit-address-action-button" @click="editAddressPopupClose()" :disabled="editAddressClickedFlag"><span class="px-2 py-2 body-text white-color-text font-weight-semi-bold">Cancel</span></button>
                                </div>
                            </div>
                        </v-card-actions>
                    </div>
                </v-card>
            </v-dialog>
            <!-- Edit Address Popup End -->

            <!-- Header Segment -->
			<div>
				<common-home-header :hide_cart="true" :callpermissionflag="callpermissionflag"></common-home-header>
                <v-divider></v-divider>
			</div>

            <!-- Web Navigation Tabs -->
            <div class="navigation-tabs-segment">
                <header-tabs-component :tab_id="1" :is_user_logged_in="isUserLoggedIn"></header-tabs-component>
                <v-divider></v-divider>
            </div>

            <!-- Mobile Header -->
            <div class="mobile-header-segment">
                <p class="ma-0 ml-5 pa-4 body-heading font-weight-semi-bold" align="left">Cart</p>

                <v-divider></v-divider>
            </div>

            <!-- Main Page Segment -->
            <div class="main-segment-background">
                <div class="container">
                    <div v-if="skeletonLoaderFlag">
                        <div class="loader-web">
                            <div class="d-flex flex-column flex-lg-row justify-lg-space-between">
                                <div class="mr-2 pa-4 order-flow-segment white-background">
                                    <v-skeleton-loader elevation="0" type="list-item-avatar" class="py-2"></v-skeleton-loader>
                                    <v-skeleton-loader elevation="0" type="image" class="pa-2"></v-skeleton-loader>

                                    <v-skeleton-loader elevation="0" type="list-item-avatar" class="py-2"></v-skeleton-loader>
                                    <v-skeleton-loader elevation="0" type="list-item-avatar" class="py-2"></v-skeleton-loader>
                                    <v-skeleton-loader elevation="0" type="list-item-avatar" class="py-2"></v-skeleton-loader>
                                    <v-skeleton-loader elevation="0" type="list-item-avatar" class="py-2"></v-skeleton-loader>
                                </div>

                                <div class="order-summary-segment">
                                    <div class="pa-4 rounded-lg white-background">
                                        <v-skeleton-loader elevation="0" type="heading" class="py-2"></v-skeleton-loader>
                                        <v-skeleton-loader elevation="0" type="image" class="py-2"></v-skeleton-loader>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="loader-mobile">
                            <div class="pa-4">
                                <v-skeleton-loader elevation="0" type="list-item-avatar" class="py-2"></v-skeleton-loader>
                                <v-skeleton-loader elevation="0" type="image" class="px-6 py-2"></v-skeleton-loader>

                                <v-skeleton-loader elevation="0" type="list-item-avatar" class="py-2"></v-skeleton-loader>
                                <v-skeleton-loader elevation="0" type="list-item-avatar" class="py-2"></v-skeleton-loader>
                                <v-skeleton-loader elevation="0" type="list-item-avatar" class="py-2"></v-skeleton-loader>
                                <v-skeleton-loader elevation="0" type="list-item-avatar" class="py-2"></v-skeleton-loader>
                            </div>

                            <div class="light-grey-dashed-border dashed-border-line"></div>

                            <div class="pa-4">
                                <v-skeleton-loader elevation="0" type="heading" class="py-2"></v-skeleton-loader>
                                <v-skeleton-loader elevation="0" type="image" class="py-2"></v-skeleton-loader>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <!-- Empty Cart -->
                        <div v-if="emptyCartFlag" class="rounded-lg white-background">
                            <!-- Heading -->
                            <p class="pa-4 ma-0 pb-2 body-heading font-weight-semi-bold cart-heading" align="left">Cart</p>

                            <div class="d-flex justify-center align-center empty-cart-segment">
                                <div class="pa-4 empty-cart-web-width">
                                    <div class="">
                                        <img src="https://s3iconimages.mymedicine.com.mm/empty_cart_icon.svg" alt="empty-cart-icon">
                                        <p class="ma-0 py-2 title-heading font-weight-semi-bold">Your Cart is Empty</p>

                                        <p class="ma-0 py-2 body-text font-weight-regular">Your wellness journey starts now. Discover our wide range of products!</p>

                                        <div class="py-2 empty-cart-actions-web">
                                            <button class="mb-2 px-4 py-2 rounded-lg empty-cart-action-button body-heading font-weight-medium light-blue-background white-color-text" @click="redirectTo('AllCategoriesPage')">Explore Categories</button>
                                            <button class="mt-2 ml-4 px-4 py-2 rounded-lg empty-cart-action-button body-heading font-weight-medium light-blue-border light-blue-color-text" @click="redirectTo('CommonHomePage')">Go Home</button>
                                        </div>
                                        <div class="py-2 empty-cart-actions-mobile">
                                            <button class="mb-2 px-4 py-2 rounded-lg full-width-button body-heading font-weight-medium light-blue-background white-color-text"  @click="redirectTo('AllCategoriesPage')">Explore Categories</button>
                                            <button class="mt-2 px-4 py-2 rounded-lg full-width-button body-heading font-weight-medium light-blue-border light-blue-color-text" @click="redirectTo('CommonHomePage')">Go Home</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="d-flex flex-column flex-lg-row justify-lg-space-between">
                            <!-- Order Flow Segment -->
                            <div class="mr-2 pa-4 order-flow-segment white-background">
                                <div>
                                    <!-- Steps -->
                                    <div class="pt-2">
                                        <!-- Step 1: Review Products & their Quantity -->
                                        <div id="step-1" class="mb-4 rounded-lg" :class="[step1Flag ? 'light-grey-border' : '']">
                                            <!-- Step 1 header -->
                                            <div class="px-4 py-2 d-flex justify-space-between align-center grey-background" :class="[step1Flag ? 'rounded-lg rounded-bl-0 rounded-br-0' : 'rounded-lg']"  @click="step1Flag=!step1Flag">
                                                <div class="d-flex align-center">
                                                    <p class="ma-0 font-16px font-weight-semi-bold white-color-text step-number" :class="[step1Flag || step1Completed ? 'dark-blue-background' : 'dark-grey-background']">1</p>
                                                    <p class="ma-0 pl-2 body-heading font-weight-semi-bold dark-blue-color-text" align="left">CART</p>
                                                </div>

                                                <img v-if="step1Completed" class="clickable-pointer" src="https://s3iconimages.mymedicine.com.mm/dropdown_blue_icon.svg" alt="dropdown-icon">
                                            </div>

                                            <!-- Step 1 Content -->
                                            <div class="pa-4 d-flex flex-column" v-if="step1Flag && cartData.products && cartData.products.length > 0">
                                                <!-- Cart Quantity -->
                                                <div class="pb-2">
                                                    <p class="ma-0 body-text font-weight-semi-bold light-grey-color-text" align="left">{{ cartData.products.length }} ITEMS IN CART</p>
                                                </div>

                                                <!-- Prescription Products -->
                                                <div v-if="prescriptionRequiredProducts.length > 0" class="py-2 d-flex flex-column">
                                                    <!-- Heading -->
                                                    <div class="d-flex align-center">
                                                        <img class="px-icon" src="https://s3iconimages.mymedicine.com.mm/dark_blue_prescription_icon.svg" alt="prescription-required">
                                                        <p class="ma-0 pl-2 body-tag font-weight-semi-bold dark-blue-color-text" align="left">PRESCRIPTION REQUIRED ITEMS</p>
                                                    </div>

                                                    <!-- Products -->
                                                    <div class="pt-2">
                                                        <div v-for="product, index in prescriptionRequiredProducts" :key="index">
                                                            <cart-product-component :key="quantityComponent" @handleRemoveProduct="handleRemoveProduct" class="product-component" :product_data="product" quantity_editable="true"></cart-product-component>
                                                            <div v-if="index < prescriptionRequiredProducts.length - 1" class="py-2">
                                                                <v-divider></v-divider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Non Prescription Products -->
                                                <div v-if="nonPrescriptionRequiredProducts.length > 0" class="pt-4 pb-2 d-flex flex-column">
                                                    <!-- Heading -->
                                                    <div class="d-flex align-center">
                                                        <img class="px-icon" src="https://s3iconimages.mymedicine.com.mm/grey_prescription_icon.svg" alt="non-prescription-required">
                                                        <p class="ma-0 pl-2 body-tag font-weight-semi-bold dark-grey-color-text" align="left">NON-PRESCRIPTION REQUIRED ITEMS</p>
                                                    </div>

                                                    <!-- Products -->
                                                    <div class="pt-2">
                                                        <div v-for="product, index in nonPrescriptionRequiredProducts" :key="index">
                                                            <cart-product-component :key="quantityComponent" @handleRemoveProduct="handleRemoveProduct" class="product-component" :product_data="product" quantity_editable="true"></cart-product-component>

                                                            <div v-if="index < nonPrescriptionRequiredProducts.length - 1" class="py-2">
                                                                <v-divider></v-divider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Continue Button -->
                                                <div class="pt-4">
                                                    <button class="ma-0 px-4 py-2 rounded body-heading font-weight-semi-bold light-blue-background white-color-text full-width-button text-transform-none-class" @click="step1Completed=true; step1Flag=false; step2Flag=true;scrollIntoView('step-2')">Continue</button>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Step 2: Prescription -->
                                        <div id="step-2" class="my-4 rounded-lg" :class="[step2Flag ? 'light-grey-border' : '']">
                                            <!-- Step 2 header -->
                                            <div class="px-4 py-2 d-flex justify-space-between align-center grey-background" :class="[step2Flag ? 'rounded-lg rounded-bl-0 rounded-br-0' : 'rounded-lg']" @click="step1Completed ? step2Flag=!step2Flag : step2Flag=false">
                                                <div class="d-flex">
                                                    <p class="ma-0 font-16px font-weight-semi-bold white-color-text step-number" :class="[step2Flag || step2Completed ? 'dark-blue-background' : 'dark-grey-background']">2</p>
                                                    <p class="ma-0 pl-2 body-heading font-weight-semi-bold" :class="[step2Flag || step2Completed ? 'dark-blue-color-text' : 'light-grey-color-text']" align="left">PRESCRIPTION</p>
                                                </div>

                                                <img v-if="step2Completed" class="clickable-pointer" src="https://s3iconimages.mymedicine.com.mm/dropdown_blue_icon.svg" alt="dropdown-icon">
                                            </div>

                                            <!-- Step 2 Content -->
                                            <div class="pa-4" v-if="step2Flag">
                                                <p class="ma-0 body-heading font-weight-semi-bold" align="left">Upload Prescription <span v-if="prescriptionRequiredProducts.length == 0" class="light-blue-color-text">(Optional)</span></p>
                                                <p class="ma-0 body-text font-weight-regular py-2 light-grey-color-text" align="left">Please attach a prescription to proceed</p>

                                                <!-- Upload Options -->
                                                <div class="py-2 upload-prescription-segment">
                                                    <div class="pa-4 light-grey-border rounded-lg">
                                                        <div class="d-flex justify-space-between align-center">
                                                            <div class="d-flex flex-column justify-flex-start upload-options-content">
                                                                <img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/upload_prescription_cart_icon.svg" alt="upload-image">
                                                                <p class="ma-0 pt-4 body-text font-weight-medium">Upload file from your device</p>
                                                            </div>

                                                            <div class="upload-options-button">
                                                                <button class="px-3 py-1 rounded-lg full-width-button dark-blue-border button-class text-transform-none-class" @click="openFileInput()">
                                                                    <span class="body-heading font-weight-semi-bold dark-blue-color-text">Upload</span>
                                                                </button>

                                                                <v-file-input ref="fileInput" v-model="uploadedFile" @change="handleFileUpload()" style="display: none;"></v-file-input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <!-- <div class="pa-4 light-grey-border rounded-lg from-device-segment">
                                                        <div class="d-flex justify-space-between upload-options-layout">
                                                            <div class="d-flex justify-flex-start upload-options-content">
                                                                <img class="icon-class" src="https://d3vsnl8idgwrlw.cloudfront.net/upload_prescription_cart_icon.svg" alt="upload-image">
                                                                <p class="ma-0 pl-sm-2 body-text font-weight-medium" align="left">Upload file from your device</p>
                                                            </div>

                                                            <div class="upload-options-button">
                                                                <button class="px-3 py-1 rounded-lg dark-blue-border button-class text-transform-none-class" @click="openFileInput()">
                                                                    <span class="body-heading font-weight-semi-bold dark-blue-color-text">Upload</span>
                                                                </button>

                                                                <v-file-input ref="fileInput" v-model="uploadedFile" @change="handleFileUpload()" style="display: none;"></v-file-input>
                                                            </div>
                                                        </div>
                                                    </div> -->

                                                    <!-- <div class="pa-4 light-grey-border rounded-lg d-flex justify-flex-start from-previous-consultation-segment">
                                                        <div class="d-flex justify-spce-between upload-options-layout">
                                                            <div class="d-flex justify-space-between upload-options-content">
                                                                <img class="icon-class" src="https://d3vsnl8idgwrlw.cloudfront.net/choose_from_prev_consultations_icon.svg" alt="choose-prescription">
                                                                <p class="ma-0 pl-sm-2 body-text font-weight-medium d-flex justify-center align-center" align="left">Choose from previous consultations</p>
                                                            </div>

                                                            <div class="upload-options-button">
                                                                <button class="px-3 py-1 rounded-lg body-heading font-weight-semi-bold light-orange-border light-orange-color-text button-class text-transform-none-class">Choose</button>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>

                                                <!-- Uploaded Prescription -->
                                                <div v-if="uploadedPrescriptions.length > 0" class="py-2">
                                                    <p class="ma-0 body-heading font-weight-semi-bold" align="left">Uploaded prescription</p>

                                                    <div class="my-2 pa-4 d-flex justify-flex-start flex-wrap rounded-lg light-grey-border uploaded-prescriptions-container">
                                                        <!-- <div v-if="uploadedPrescriptions.length == 0">
                                                            <img src="https://d3vsnl8idgwrlw.cloudfront.net/no_uploads_icon.svg" alt="no-prescriptions">
                                                            <p class="ma-0 body-heading font-weight-medium light-grey-color-text">No uploads yet</p>
                                                        </div> -->

                                                        <!-- <div class=""> -->
                                                            <div v-for="prescription, index in uploadedPrescriptions" :key="index">
                                                                <!-- <img :src="prescription.file_url" :alt="'prescription-'+index"> -->
                                                                <!-- <p class="ma-0 body-heading font-weight-medium" align="left">{{ prescription.file_name }}</p> -->
                                                                <div class="prescription-image-container">
                                                                    <img class="prescription-thumbnail rounded-lg light-grey-border" :src="prescription.file_url" :alt="'prescription-'+index">
                                                                    <div class="white-background rounded-lg">
                                                                        <img class="thumbnail-close-icon white-background rounded-xl prescription-remove-icon" src="https://s3iconimages.mymedicine.com.mm/enclosed_close_button_red_icon.svg" alt="close-icon" @click="removePrescription(index)">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        <!-- </div> -->
                                                    </div>
                                                </div>

                                                <!-- Continue Button -->
                                                <div class="pt-2">
                                                    <button class="ma-0 px-4 py-2 rounded body-heading font-weight-semi-bold full-width-button text-transform-none-class" :class="[uploadedPrescriptions.length == 0 && prescriptionRequiredProducts.length != 0 ? 'grey-background light-grey-color-text' : 'light-blue-background white-color-text']" :disabled="uploadedPrescriptions.length == 0 && prescriptionRequiredProducts.length != 0" @click="step2Completed=true; step2Flag=false; step3Flag=true;scrollIntoView('step-3');">Continue</button>
                                                    <!-- <button class="ma-0 px-4 py-2 rounded white-color-text full-width-button text-transform-none-class" :class="[uploadedPrescriptions.length == 0 ? 'grey-background' : 'light-blue-background']" :disabled="uploadedPrescriptions.length == 0" @click="step2Completed=true; step2Flag=false; step3Flag=true;">Continue</button> -->
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Step 3: Address -->
                                        <div id="step-3" class="my-4 rounded-lg" :class="[step3Flag ? 'light-grey-border' : '']">
                                            <!-- Step 3 header -->
                                            <div class="px-4 py-2 d-flex justify-space-between align-center grey-background" :class="[step3Flag ? 'rounded-lg rounded-bl-0 rounded-br-0' : 'rounded-lg']" @click="step2Completed ? step3Flag=!step3Flag : step3Flag=false">
                                                <div class="d-flex">
                                                    <p class="ma-0 font-16px font-weight-semi-bold white-color-text step-number" :class="[step3Flag || step3Completed ? 'dark-blue-background' : 'dark-grey-background']">3</p>
                                                    <p class="ma-0 pl-2 body-heading font-weight-semi-bold" :class="[step3Flag || step3Completed ? 'dark-blue-color-text' : 'light-grey-color-text']" align="left">ADDRESS</p>
                                                </div>

                                                <img v-if="step3Completed" class="clickable-pointer" src="https://s3iconimages.mymedicine.com.mm/dropdown_blue_icon.svg" alt="dropdown-icon">
                                            </div>

                                            <!-- Step 3 Content -->
                                            <div class="pa-4" v-if="step3Flag">
                                                <!-- Shipping Address -->
                                                <div class="address-list-section">
                                                    <p class="mb-2 body-heading font-weight-semi-bold light-grey-color-text" align="left">Shipping Address</p>

                                                    <div class="pa-4 rounded-lg light-grey-border shipping-address-web-component">
                                                        <label v-if="cartData.addresses.length > 0" v-for="address, index in cartData.addresses" :key="index">
                                                            <!-- Existing Addresses -->
                                                            <div v-if="!address.add_address" class="address-radio-item clickable-pointer">
                                                                <div class="d-flex justify-space-between align-center">
                                                                    <div>
                                                                        <input type="radio" :id="index" :label="address.address_unique_id" :value="address.address_unique_id" v-model="selectedShippingAddressId" align="left">
                                                                        <span class="pl-2 body-heading font-weight-medium">{{ address.address_type_name }}</span>
                                                                    </div>

                                                                    <div class="d-flex align-center">
                                                                        <div class="px-4 py-2 pr-3 d-flex align-center" >
                                                                            <v-btn icon v-on:click.stop="editAddressPopupOpen(index);"><img src="https://s3iconimages.mymedicine.com.mm/edit_icon_black.svg" alt="edit-icon"></v-btn>
                                                                            <!-- <span class="body-text font-weight-regular pl-2">Edit</span> -->
                                                                        </div>
                                                                        
                                                                        <!-- <div class="pl-3 d-flex align-center">
                                                                            <img src="https://d3vsnl8idgwrlw.cloudfront.net/delete_icon_red.svg" alt="delete-icon">
                                                                            <span class="pl-2 body-text font-weight-regular light-red-color-text">Remove</span>
                                                                        </div> -->
                                                                    </div>
                                                                </div>

                                                                <div class="pa-4">
                                                                    <p class="ma-0 body-text font-weight-regular light-grey-color-text" align="left">{{ address.delivered_to_name }},</p>
                                                                    <p class="ma-0 body-text font-weight-regular light-grey-color-text" align="left">
                                                                        {{ address.house_number }}, {{ address.street_address }} - {{ address.state.name }}
                                                                    </p>
                                                                </div>

                                                                <div class="py-4">
                                                                    <v-divider></v-divider>
                                                                </div>
                                                            </div>

                                                            <!-- Add New Address -->
                                                            <div v-else class="address-radio-item clickable-pointer">
                                                                <input type="radio" :id="index" :label="address.address_unique_id" :value="address.address_type_name" v-model="selectedShippingAddressId" align="left">
                                                                <strong class="pl-2 body-heading font-weight-medium">Add Address</strong>
                                                                
                                                                <div v-if="selectedShippingAddressId == 'new'" class="px-4 pt-2" @click.prevent="(e) => {e.preventDefault();}">
                                                                    <div>
                                                                        <!-- Deliver To & Mobile Number -->
                                                                        <div class="pb-2 d-flex flex-wrap">
                                                                            <div class="pr-2 half-width d-flex flex-column">
                                                                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">Deliver To*</p>
                                                                                
                                                                                <v-text-field solo flat placeholder="Enter Name" v-model="addAddressData.delivered_to_name" hide-details="auto" class="ma-0 pa-0 body-text light-grey-border"
                                                                                    @click="" @keyup.enter="" @click:append=""
                                                                                ></v-text-field>
                                                                            </div>

                                                                            <div class="pl-2 half-width d-flex flex-column">
                                                                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">Mobile Number*</p>
                                                                                
                                                                                <v-text-field type="number" solo flat placeholder="Enter Mobile Number" v-model="addAddressData.phone_number" hide-details="auto" class="ma-0 pa-0 body-text light-grey-border"
                                                                                    @click="" @keyup.enter="" @click:append=""
                                                                                ></v-text-field>
                                                                            </div>
                                                                        </div>

                                                                        <!-- House no -->
                                                                        <div class="py-2">
                                                                            <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">House Number / Building Number</p>

                                                                            <v-text-field solo flat placeholder="Enter Address Line" v-model="addAddressData.house_number" hide-details="auto" class="ma-0 pa-0 body-text light-grey-border"
                                                                                @click="" @keyup.enter="" @click:append=""
                                                                            ></v-text-field>
                                                                        </div>

                                                                        <!-- Street Name / Locality -->
                                                                        <div class="pb-2 d-flex flex-wrap">
                                                                            <div class="pr-2 half-width d-flex flex-column">
                                                                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">Street Name / Locality*</p>
                                                                                
                                                                                <v-text-field solo flat placeholder="Enter Street Name" v-model="addAddressData.street_address" hide-details="auto" class="ma-0 pa-0 body-text light-grey-border"
                                                                                    @click="" @keyup.enter="" @click:append=""
                                                                                ></v-text-field>
                                                                            </div>

                                                                            <div class="pl-2 half-width d-flex flex-column">
                                                                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">Landmark</p>

                                                                                <v-text-field solo flat placeholder="Enter Landmark" v-model="addAddressData.landmark" hide-details="auto" class="ma-0 pa-0 body-text light-grey-border"
                                                                                    @click="" @keyup.enter="" @click:append=""
                                                                                ></v-text-field>
                                                                            </div>
                                                                        </div>

                                                                        <!-- State & Township -->
                                                                        <div class="py-2">
                                                                            <div class="full-width d-flex flex-column">
                                                                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">State / Township</p>
                                                                                <v-select placeholder="Select State / Township" class="ma-0 body-text rounded light-grey-border state-segment" solo flat
                                                                                    :items="addressStateList"
                                                                                    item-text="name" item-value="id"
                                                                                    return-object
                                                                                >
                                                                                    <template #append>
                                                                                        <img class="mt-1" src="https://s3iconimages.mymedicine.com.mm/StateDropdownIcon.png" alt="Search Icon">
                                                                                    </template>
                                                                                </v-select>
                                                                            </div>
                                                                        </div>

                                                                        <!-- Address Type -->
                                                                        <div>
                                                                            <div class="py-2">
                                                                                <p class="ma-0 body-heading font-weight-regular light-grey-color-text">Address Type</p>
                                                                            </div>

                                                                            <div class="py-2 d-flex justify-space-between align-center flex-wrap">
                                                                                <div class="pr-2 one-third-width">
                                                                                    <button class="mr-2 px-2 py-2 d-flex justify-center align-center rounded full-width-button" :class="[selectedShippingAddressType != null && selectedShippingAddressType == 'Home' ? 'light-blue-border light-blue-color-text' : 'light-grey-border dark-grey-color-text']" @click="selectedShippingAddressType='Home'">
                                                                                        <img :src="selectedShippingAddressType != null && selectedShippingAddressType == 'Home' ? 'https://s3iconimages.mymedicine.com.mm/AddressHomeIcon_LightBlue.svg' : 'https://s3iconimages.mymedicine.com.mm/AddressHomeIcon.svg'" alt="home-icon">
                                                                                        <p class="ma-0 pl-2 d-flex align-center body-text font-weight-medium">Home</p>
                                                                                    </button>
                                                                                </div>
                                                                                
                                                                                <div class="px-2 one-third-width">
                                                                                    <button class="mr-2 px-2 py-2 d-flex justify-center align-center rounded full-width-button" :class="[selectedShippingAddressType != null && selectedShippingAddressType == 'Work' ? 'light-blue-border light-blue-color-text' : 'light-grey-border dark-grey-color-text']" @click="selectedShippingAddressType='Work'">
                                                                                        <img :src="selectedShippingAddressType != null && selectedShippingAddressType == 'Work' ? 'https://s3iconimages.mymedicine.com.mm/AddressWorkIcon_LightBlue.svg' : 'https://s3iconimages.mymedicine.com.mm/AddressWorkIcon.svg'" alt="home-icon">
                                                                                        <p class="ma-0 pl-2 body-text font-weight-medium">Work</p>
                                                                                    </button>
                                                                                </div>

                                                                                <div class="pl-2 one-third-width">
                                                                                    <button class="mr-2 px-2 py-2 d-flex justify-center align-center rounded full-width-button" :class="[selectedShippingAddressType != null && selectedShippingAddressType != 'Home' && selectedShippingAddressType != 'Work' ? 'light-blue-border light-blue-color-text' : 'light-grey-border dark-grey-color-text']" @click="selectedShippingAddressType='Other'">
                                                                                        <p class="ma-0 body-text font-weight-medium">Other</p>
                                                                                    </button>
                                                                                </div>
                                                                            </div>

                                                                            <div v-if="selectedShippingAddressType == 'Other'" class="py-2">
                                                                                <v-text-field solo flat placeholder="Enter a Name" v-model="shippingOtherAddressTypeName" hide-details="auto" class="ma-0 pa-0 light-grey-border"
                                                                                    @click="" @keyup.enter="" @click:append=""
                                                                                ></v-text-field>
                                                                            </div>
                                                                        </div>

                                                                        <!-- Actions -->
                                                                        <div class="pt-2">
                                                                            <button class="py-2 one-third-width d-flex justify-center align-center rounded body-text font-weight-semi-bold light-blue-background white-color-text" @click="addAddress();">
                                                                                <div v-if="!addAddressClicked">
                                                                                    <p class="ma-0 body-text font-weight-medium">Save Changes</p>
                                                                                </div>
                                                                                <div v-else>
                                                                                    <v-progress-circular size="20" indeterminate color="white"></v-progress-circular>
                                                                                </div>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>

                                                    <div class="shipping-address-mobile-component">
                                                        <!-- Existing Addresses -->
                                                        <label v-if="cartData.addresses.length > 0" align="left" v-for="address, index in cartData.addresses" :key="index">
                                                            <div v-if="!address.add_address" class="pa-2 my-4 rounded-lg light-grey-border address-radio-item">
                                                                <div class="d-flex justify-space-between align-center">
                                                                    <div class="d-flex align-center">
                                                                        <input type="radio" :id="index" :label="address.address_unique_id" :value="address.address_unique_id" v-model="selectedShippingAddressId" align="left">
                                                                        <strong class="pl-2 body-heading font-weight-medium">{{ address.address_type_name }}</strong>
                                                                    </div>

                                                                    <div class="d-flex align-center">
                                                                        <div class="px-4 py-2 pr-3 d-flex align-center" >
                                                                            <v-btn icon v-on:click.stop="editAddressPopupOpen(index);"><img src="https://s3iconimages.mymedicine.com.mm/edit_icon_black.svg" alt="edit-icon"></v-btn>
                                                                            <!-- <span class="body-text font-weight-regular pl-2">Edit</span> -->
                                                                        </div>
                                                                        
                                                                        <!-- <div class="pl-3 d-flex align-center">
                                                                            <img src="https://d3vsnl8idgwrlw.cloudfront.net/delete_icon_red.svg" alt="delete-icon">
                                                                            <span class="pl-2 body-text font-weight-regular light-red-color-text">Remove</span>
                                                                        </div> -->
                                                                    </div>
                                                                </div>

                                                                <div class="px-4 py-2">
                                                                    <p class="ma-0 body-text font-weight-regular light-grey-color-text" align="left">{{ address.delivered_to_name }},</p>
                                                                    <p class="ma-0 body-text font-weight-regular light-grey-color-text" align="left">
                                                                        {{ address.house_number }}, {{ address.street_address }} - {{ address.state.name }}
                                                                    </p>
                                                                </div>

                                                                <!-- <div class="py-2">
                                                                    <v-divider></v-divider>
                                                                </div>

                                                                <div class="d-flex justify-center align-center">
                                                                    <div class="pl-3 d-flex align-center">
                                                                        <img src="https://d3vsnl8idgwrlw.cloudfront.net/delete_icon_red.svg" alt="delete-icon">
                                                                        <span class="pl-2 body-text font-weight-regular light-red-color-text">Remove</span>
                                                                    </div>

                                                                    <div class="pr-3 d-flex align-center">
                                                                        <img src="https://d3vsnl8idgwrlw.cloudfront.net/edit_icon_black.svg" alt="edit-icon">
                                                                        <span class="pl-2 body-text font-weight-regular">Edit</span>
                                                                    </div>
                                                                </div> -->
                                                            </div>

                                                            <div v-else class="mb-2 pa-4 rounded-lg light-grey-border address-radio-item">
                                                                <input type="radio" autofocus="false" :id="index" :label="address.address_unique_id" :value="address.address_type_name" v-model="selectedShippingAddressId" align="left">
                                                                <strong class="pl-2 body-heading font-weight-medium">Add Address</strong>
                                                                
                                                                <div v-if="selectedShippingAddressId == 'new'" class="px-4 pt-2" @click.prevent="(e) => {e.preventDefault();}">
                                                                    <div>
                                                                        <!-- Deliver To & Mobile Number -->
                                                                        <div class="pb-2">
                                                                            <div class="pb-2">
                                                                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">Deliver To*</p>
                                                                                
                                                                                <v-text-field solo flat placeholder="Enter Name" v-model="addAddressData.delivered_to_name" hide-details="auto" class="ma-0 pa-0 body-text light-grey-border"
                                                                                    @click="" @keyup.enter="" @click:append=""
                                                                                ></v-text-field>
                                                                            </div>

                                                                            <div class="py-2">
                                                                                <p class="ma-0 pb-2 body-heading font-weight-regular body-heading light-grey-color-text">Mobile Number*</p>
                                                                                
                                                                                <v-text-field type="number" solo flat placeholder="Enter Mobile Number" v-model="addAddressData.phone_number" hide-details="auto" class="ma-0 pa-0 light-grey-border body-text"
                                                                                    @click="" @keyup.enter="" @click:append=""
                                                                                ></v-text-field>
                                                                            </div>
                                                                        </div>

                                                                        <!-- House no -->
                                                                        <div class="py-2">
                                                                            <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">House Number / Building Number</p>

                                                                            <v-text-field solo flat placeholder="Enter Address Line" v-model="addAddressData.house_number" hide-details="auto" class="ma-0 pa-0 body-text light-grey-border"
                                                                                @click="" @keyup.enter="" @click:append=""
                                                                            ></v-text-field>
                                                                        </div>

                                                                        <!-- Street Name / Locality -->
                                                                        <div class="py-2">
                                                                            <p class="ma-0 pb-2 body-heading font-weight-regular body-heading light-grey-color-text">Street Name / Locality*</p>
                                                                            
                                                                            <v-text-field solo flat placeholder="Enter Street Name" v-model="addAddressData.street_address" hide-details="auto" class="ma-0 pa-0 body-text light-grey-border"
                                                                                @click="" @keyup.enter="" @click:append=""
                                                                            ></v-text-field>
                                                                        </div>

                                                                        <!-- Landmark -->
                                                                        <div class="py-2">
                                                                            <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">Landmark</p>

                                                                            <v-text-field solo flat placeholder="Enter Landmark" v-model="addAddressData.landmark" hide-details="auto" class="ma-0 pa-0 body-text light-grey-border"
                                                                                @click="" @keyup.enter="" @click:append=""
                                                                            ></v-text-field>
                                                                        </div>

                                                                        <!-- State / Township -->
                                                                        <div class="py-2">
                                                                            <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">State / Township</p>
                                                                            <v-select class="ma-0 body-text rounded light-grey-border state-segment" solo flat
                                                                                :items="addressStateList"
                                                                                item-text="name" item-value="id"
                                                                                return-object placeholder="Select State / Township"
                                                                                v-model="addAddressData.state"
                                                                            >
                                                                                <template #append>
                                                                                    <img class="mt-1" src="https://s3iconimages.mymedicine.com.mm/StateDropdownIcon.png" alt="Search Icon">
                                                                                </template>
                                                                            </v-select>
                                                                        </div>

                                                                        <!-- Address Type -->
                                                                        <div>
                                                                            <div class="pt-2">
                                                                                <p class="ma-0 body-heading font-weight-regular light-grey-color-text">Address Type</p>
                                                                            </div>

                                                                            <div class="py-2 d-flex justify-space-between align-center flex-wrap">
                                                                                <div class="pr-2 one-third-width">
                                                                                    <button class="mr-2 px-2 py-2 d-flex justify-center align-center rounded full-width-button" :class="[selectedShippingAddressType != null && selectedShippingAddressType == 'Home' ? 'light-blue-border light-blue-color-text' : 'light-grey-border dark-grey-color-text']" @click="selectedShippingAddressType='Home'">
                                                                                        <img :src="selectedShippingAddressType != null && selectedShippingAddressType == 'Home' ? 'https://s3iconimages.mymedicine.com.mm/AddressHomeIcon_LightBlue.svg' : 'https://s3iconimages.mymedicine.com.mm/AddressHomeIcon.svg'" alt="home-icon">
                                                                                        <p class="ma-0 pl-2 d-flex align-center body-text font-weight-medium">Home</p>
                                                                                    </button>
                                                                                </div>
                                                                                
                                                                                <div class="px-2 one-third-width">
                                                                                    <button class="mr-2 px-2 py-2 d-flex justify-center align-center rounded full-width-button" :class="[selectedShippingAddressType != null && selectedShippingAddressType == 'Work' ? 'light-blue-border light-blue-color-text' : 'light-grey-border dark-grey-color-text']" @click="selectedShippingAddressType='Work'">
                                                                                        <img :src="selectedShippingAddressType != null && selectedShippingAddressType == 'Work' ? 'https://s3iconimages.mymedicine.com.mm/AddressWorkIcon_LightBlue.svg' : 'https://s3iconimages.mymedicine.com.mm/AddressWorkIcon.svg'" alt="home-icon">
                                                                                        <p class="ma-0 pl-2 d-flex align-center body-text font-weight-medium">Work</p>
                                                                                    </button>
                                                                                </div>

                                                                                <div class="pl-2 one-third-width">
                                                                                    <button class="mr-2 px-2 py-2 d-flex justify-center align-center rounded full-width-button" :class="[selectedShippingAddressType != null && selectedShippingAddressType != 'Home' && selectedShippingAddressType != 'Work' ? 'light-blue-border light-blue-color-text' : 'light-grey-border dark-grey-color-text']" @click="selectedShippingAddressType='Other'">
                                                                                        <p class="ma-0 body-text font-weight-medium">Other</p>
                                                                                    </button>
                                                                                </div>
                                                                            </div>

                                                                            <div v-if="selectedShippingAddressType == 'Other'" class="py-2">
                                                                                <v-text-field solo flat placeholder="Enter a Name" v-model="shippingOtherAddressTypeName" hide-details="auto" class="ma-0 pa-0 body-text light-grey-border"
                                                                                    @click="" @keyup.enter="" @click:append=""
                                                                                ></v-text-field>
                                                                            </div>
                                                                        </div>

                                                                        <!-- Actions -->
                                                                        <div class="pt-2 d-flex">
                                                                            <button v-if="!addAddressClicked" class="px-2 py-2 half-width d-flex justify-center align-center rounded body-text font-weight-semi-bold light-blue-background white-color-text" @click="addAddress()">
                                                                                <p class="ma-0 pl-2 body-text font-weight-medium">Save</p>
                                                                            </button>
                                                                            <div v-else>
                                                                                <v-progress-circular size="20" class="ml-2" indeterminate color="#48ACEF"></v-progress-circular>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>

                                                <!-- Continue Button -->
                                                <div class="mt-4">
                                                    <button class="ma-0 px-4 py-2 rounded body-heading font-weight-semi-bold light-blue-background white-color-text full-width-button text-transform-none-class" @click="step3Completed=true; step3Flag=false; step4Flag=true;scrollIntoView('step-4');">Continue</button>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Step 4: Order Summary -->
                                        <div id="step-4" class="my-4 rounded-lg" :class="[step4Flag ? 'light-grey-border' : '']">
                                            <!-- Step 4 header -->
                                            <div class="px-4 py-2 d-flex justify-space-between align-center grey-background" :class="[step4Flag ? 'rounded-lg rounded-bl-0 rounded-br-0' : 'rounded-lg']" @click="step3Completed ? step4Flag=!step4Flag : step4Flag=false">
                                                <div class="d-flex">
                                                    <p class="ma-0 font-16px font-weight-semi-bold white-color-text step-number" :class="[step4Flag || step4Completed ? 'dark-blue-background' : 'dark-grey-background']">4</p>
                                                    <p class="ma-0 pl-2 body-heading font-weight-semi-bold" :class="[step4Flag || step4Completed ? 'dark-blue-color-text' : 'light-grey-color-text']" align="left">ORDER SUMMARY</p>
                                                </div>

                                                <img v-if="step4Completed" class="clickable-pointer" src="https://s3iconimages.mymedicine.com.mm/dropdown_blue_icon.svg" alt="dropdown-icon">
                                            </div>

                                            <!-- Step 4 Content -->
                                            <div class="pa-4" v-if="step4Flag">
                                                <!-- Cart Quantity -->
                                                <div class="pb-2">
                                                    <p class="ma-0 body-text font-weight-semi-bold light-grey-color-text" align="left">{{ cartData.products.length }} ITEMS IN CART</p>
                                                </div>

                                                <!-- Prescription Products -->
                                                <div v-if="prescriptionRequiredProducts.length > 0" class="py-2 d-flex flex-column">
                                                    <!-- Heading -->
                                                    <div class="d-flex align-center">
                                                        <img src="https://s3iconimages.mymedicine.com.mm/dark_blue_prescription_icon.svg" alt="prescription-required">
                                                        <p class="ma-0 pl-2 body-text font-weight-semi-bold dark-blue-color-text" align="left">PRESCRIPTION REQUIRED ITEMS</p>
                                                    </div>

                                                    <!-- Products -->
                                                    <div class="pt-2">
                                                        <div v-for="product, index in prescriptionRequiredProducts" :key="index">
                                                            <cart-product-component :key="quantityComponent" @handleRemoveProduct="handleRemoveProduct" class="product-component" :product_data="product" quantity_editable="false"></cart-product-component>
                                                            <div v-if="index < prescriptionRequiredProducts.length - 1" class="py-2">
                                                                <v-divider></v-divider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Non Prescription Products -->
                                                <div v-if="nonPrescriptionRequiredProducts.length > 0" class="pt-4 pb-2 d-flex flex-column">
                                                    <!-- Heading -->
                                                    <div class="d-flex align-center">
                                                        <img src="https://s3iconimages.mymedicine.com.mm/grey_prescription_icon.svg" alt="non-prescription-required">
                                                        <p class="ma-0 pl-2 body-text font-weight-semi-bold dark-grey-color-text" align="left">NON-PRESCRIPTION REQUIRED ITEMS</p>
                                                    </div>

                                                    <!-- Products -->
                                                    <div class="pt-2">
                                                        <div v-for="product, index in nonPrescriptionRequiredProducts" :key="index">
                                                            <cart-product-component @handleRemoveProduct="handleRemoveProduct" :key="quantityComponent" class="product-component" :product_data="product" quantity_editable="false"></cart-product-component>

                                                            <div v-if="index < nonPrescriptionRequiredProducts.length - 1" class="py-2">
                                                                <v-divider></v-divider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Continue Button -->
                                                <div class="pt-4">
                                                    <button class="ma-0 px-4 py-2 rounded body-heading font-weight-semi-bold light-blue-background white-color-text full-width-button text-transform-none-class" @click="step4Completed=true; step4Flag=false; step5Flag=true;scrollIntoView('step-5');">Choose Payment Method</button>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Step 5: Payment -->
                                        <div id="step-5" class="mt-4 rounded-lg" :class="[step5Flag ? 'light-grey-border' : '']">
                                            <!-- Step 5 header -->
                                            <div class="px-4 py-2 d-flex justify-space-between align-center grey-background" :class="[step5Flag ? 'rounded-lg rounded-bl-0 rounded-br-0' : 'rounded-lg']" @click="step4Completed ? step5Flag=!step5Flag : step5Flag=false">
                                                <div class="d-flex">
                                                    <p class="ma-0 font-16px font-weight-semi-bold white-color-text step-number" :class="[step5Flag || step5Completed ? 'dark-blue-background' : 'dark-grey-background']">5</p>
                                                    <p class="ma-0 pl-2 body-heading font-weight-semi-bold" :class="[step5Flag || step5Completed ? 'dark-blue-color-text' : 'light-grey-color-text']" align="left">PAYMENT</p>
                                                </div>

                                                <img v-if="step5Completed" class="clickable-pointer" src="https://s3iconimages.mymedicine.com.mm/dropdown_blue_icon.svg" alt="dropdown-icon">
                                            </div>

                                            <!-- Step 5 Content -->
                                            <div class="pa-4" v-if="step5Flag">
                                                <div class="pb-2 d-flex justify-flex-start align-center clickable-pointer">
                                                    <input type="radio" id="kbzpay" :label="paymentMode" :value="paymentMode" v-model="paymentMode">
                                                    <img class="pl-2" src="https://s3iconimages.mymedicine.com.mm/KBZPay_icon.svg" alt="kbzpay-icon">
                                                    <p class="ma-0 pl-2 body-text font-weight-semi-bold">KBZ Pay</p>
                                                </div>

                                                <!-- Continue Button -->
                                                <div class="pt-2">
                                                    <button class="ma-0 px-4 py-2 rounded body-heading font-weight-semi-bold light-blue-background white-color-text full-width-button text-transform-none-class" @click="step5Completed=true; step5Flag=false;">Pay Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Order Summary Segment -->
                            <div v-if="emptyCartFlag">
                            
                            </div>
                            <div v-else class="order-summary-segment">
                                <div class="light-grey-dashed-border dashed-border-line"></div>
                                
                                <div class="ml-lg-2">
                                    <div class="pa-4 rounded-lg white-background">
                                        <!-- Heading -->
                                        <p class="ma-0 pb-4 body-heading font-weight-semi-bold" align="left">Order Details</p>

                                        <!-- Billing Details -->
                                        <div class="mb-4 pa-4 rounded-lg light-grey-border">
                                            <p class="ma-0 body-text font-weight-semi-bold" align="left">Billing Details</p>

                                            <div class="py-4">
                                                <v-divider></v-divider>
                                            </div>

                                            <div class="d-flex flex-column">
                                                <div class="pb-2 d-flex justify-space-between">
                                                    <p class="ma-0 body-text font-weight-regular">Item Total</p>
                                                    <p class="ma-0 body-text font-weight-regular">{{ totalPrice }} MMK</p>
                                                </div>
                                            </div>

                                            <div class="py-4">
                                                <v-divider></v-divider>
                                            </div>

                                            <div class="d-flex justify-space-between">
                                                <p class="ma-0 body-heading font-weight-semi-bold light-blue-color-text">Order Total</p>
                                                <p class="ma-0 body-heading font-weight-semi-bold light-blue-color-text">MMK {{ totalPrice }}</p>
                                            </div>
                                        </div>

                                        <!-- Prescriptions -->
                                        <div class="mb-4 pa-4 rounded-lg light-grey-border" v-if="uploadedPrescriptions.length > 0">
                                            <!-- Heading -->
                                            <div class="d-flex justify-space-between">
                                                <p class="ma-0 body-text font-weight-semi-bold" align="left">Uploaded Prescription</p>
                                                <p class="ma-0 body-text font-weight-semi-bold light-blue-color-text" align="left">Reupload</p>
                                            </div>

                                            <!-- Image Thumbnails -->
                                            <div class="py-4 d-flex justify-flex-start uploaded-prescriptions-container" v-for="prescription, index in uploadedPrescriptions" :key="index">
                                                <!-- <img class="prescription-thumbnail" :src="prescription.file_url" :alt="'prescription-'+index"> -->
                                                <div class="prescription-image-container">
                                                    <img class="prescription-thumbnail rounded-lg light-grey-border" :src="prescription.file_url" :alt="'prescription-'+index">
                                                    <div class="white-background rounded-lg">
                                                        <img class="thumbnail-close-icon white-background rounded-xl prescription-remove-icon" src="https://s3iconimages.mymedicine.com.mm/enclosed_close_button_red_icon.svg" alt="close-icon" @click="removePrescription(index)">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Shipping Address -->
                                        <div class="mb-4 pa-4 rounded-lg light-grey-border" v-if="selectedShippingAddressId">
                                            <!-- Heading -->
                                            <div class="d-flex justify-space-between">
                                                <p class="ma-0 body-text font-weight-semi-bold" align="left">Shipping Address</p>
                                                <!-- <p class="ma-0 body-text font-weight-semi-bold light-blue-color-text" align="left">Change</p> -->
                                            </div>

                                            <!-- Address segment -->
                                            <div class="mt-4 py-2 rounded-lg grey-background">
                                                <div class="pa-4">
                                                    <p class="ma-0 body-text font-weight-medium dark-grey-color-text" align="left">Deliver to {{ shippingAddress.address_type_name }}</p>
                                                    <p class="ma-0 body-text font-weight-regular light-grey-color-text" align="left">{{ shippingAddress.delivered_to_name }},</p>
                                                    <p class="ma-0 body-text font-weight-regular light-grey-color-text" align="left">
                                                        {{ shippingAddress.house_number }}, {{ shippingAddress.street_address }}, - {{ shippingAddress.state.name }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <!-- Add a Note -->
                                        <!-- <div class="my-4 pa-4 rounded-lg light-grey-border"> -->
                                            <!-- Heading -->
                                            <!-- <p class="ma-0 pb-4 body-heading font-weight-semi-bold" align="left">Add a Note</p> -->

                                            <!-- Text Area -->
                                            <!-- <div class="pa-2 rounded-lg dark-grey-border">
                                                <v-textarea class="pa-0" placeholder="Write a note" color="black" auto-grow rows="1" row-height="60" v-model="description"></v-textarea>
                                            </div> -->
                                        <!-- </div> -->

                                        <!-- Cancellation Policy & FAQs -->
                                        <div class="redirection-tags">
                                            <div class="mt-4 d-flex justify-center align-center">
                                                <div class="d-flex align-center">
                                                    <img class="footer-icon" src="https://s3iconimages.mymedicine.com.mm/info_icon_light_blue.svg" alt="info-icon">
                                                    <p class="ma-0 pl-2 body-text font-weight-medium">Cancellation Policy</p>
                                                </div>

                                                <div class="pl-4 d-flex align-center">
                                                    <img class="footer-icon" src="https://s3iconimages.mymedicine.com.mm/help_center_common_home_icon.svg" alt="">
                                                    <p class="ma-0 pl-2 body-text font-weight-medium">FAQs</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Footer -->
            <div class="footer-segment">
                <v-divider></v-divider>
            </div>
            <div class="pa-4 footer-segment">
                <common-home-footer></common-home-footer>
            </div>
        </v-app>
    </div>
</template>

<script>
import axios from 'axios';
import { axios_auth_instance_epharmacy_customer } from '../../../utils/axios_utils';
export default {
    name: 'ePharmacyCartPage',
    components: {
        'common-home-header': () => import('../../../components/navigationComponents/commonHomeHeader.vue'),
		'header-tabs-component': () => import('../../../components/navigationComponents/headerTabsComponent.vue'),
        'cart-product-component': () => import('../../../components/ePharmacyComponents/cartProductComponent.vue'),
        'bottom-navigation-bar': () => import('../../../components/navigationComponents/bottomNavigationBar.vue'),
        'common-home-footer': () => import('../../../components/navigationComponents/commonHomeFooter.vue')
    },
    data() {
        return {
            screenWidth: window.innerWidth,
            skeletonLoaderFlag: true,
            customerToken: null,
            step1Flag: true,
            step2Flag: false,
            step3Flag: false,
            step4Flag: false,
            step5Flag: false,
            step1Completed: false,
            step2Completed: false,
            step3Completed: false,
            step4Completed: false,
            step5Completed: false,
            emptyCartFlag: false,
            cartData: {
                products: [],
                addresses: []
            },
            prescriptionRequiredProducts: [],
            nonPrescriptionRequiredProducts: [],
            uploadedPrescriptions: [],
            addressStateList: null,
            addressCountryList: null,
            selectedShippingAddressId: null,
            description: '',
            fileUpload: false,
            fileUploadRules: [
                value => !!value || value.size > 100000 || 'File size too large. Maximum limit allowed 10mb.'
            ],
            uploadedFile: null,
            addAddressClicked: false,
            addAddressData: {
                country: 'Myanmar'
            },
            selectedShippingAddressType: null,
            shippingOtherAddressTypeName: null,
            totalPrice: null,
            quantityComponent: 0,
            editAddressPopupLoader: false,
            editAddressPopup: false,
            editAddressData: {},
            editAddressClickedFlag: false,
            shippingAddress: {},
            paymentMode: 'KBZ Pay',
            callpermissionflag: false
        };
    },
    watch: {
		screenWidth(newWidth, oldWidth) {
			console.log('Screen Width Changed:', newWidth);
		},
        selectedShippingAddressId(newSelectedShippingAddressId) {
            if(newSelectedShippingAddressId != 'Other' && newSelectedShippingAddressId != 'new') {
                let selectedShippingAddressIndex = this.cartData.addresses.findIndex((address) => address.address_unique_id === newSelectedShippingAddressId);
                this.shippingAddress = this.cartData.addresses[selectedShippingAddressIndex];
            }

            if (newSelectedShippingAddressId == 'new') {
                // Fetch state & country codes from BE.
                axios_auth_instance_epharmacy_customer.get('/address/stateCountryList').then((getStateCountryIdListResponse) =>{
                    this.addressStateList = getStateCountryIdListResponse.data.data.listOfState;
                    this.addressCountryList = getStateCountryIdListResponse.data.data.listOfCountry;
                }).catch((getStateCountryIdListError) => {
                    console.log('Error fetch state & country list: ', getStateCountryIdListError);
                });
            }
        },
	},
    mounted() {
        window.addEventListener('resize', this.handleResize);
        
        // Call viewParticularCart API.
        axios_auth_instance_epharmacy_customer.get('/cart').then((viewParticularCartResponse) => {
            // Login Check.
            this.isUserLoggedIn = true;

            this.cartDataResponse = viewParticularCartResponse.data.data[0];

            this.totalPrice = this.cartDataResponse.amount_total;
            this.cartData.products = this.cartDataResponse.orderDetails ? this.cartDataResponse.orderDetails : [];
            
            // Fetch Addresses.
            this.cartData.addresses = viewParticularCartResponse.data.delivery_address ? viewParticularCartResponse.data.delivery_address : [];
            this.cartData.addresses.push({
                address_unique_id: 'new',
                address_type_name: 'new',
                add_address: true
            });

            this.emptyCartFlag = !(this.cartData.products && this.cartData.products.length > 0);
            
            this.selectedShippingAddressId = this.cartData.addresses[0].address_unique_id;

            this.shippingAddress = this.cartData.addresses[0];

            // Check stock against each product.
            // every((product) => product.stock_qty >= product.cart_qty);
            let outOfStockProductsList = [];
            let stockFlag = this.cartData.products.forEach((product) => {
                if (product.stock_qty < product.cart_qty) {
                    console.log('index with product stock not matched');
                    outOfStockProductsList.push(product);
                }

                if (product.prescription_required) {
                    this.prescriptionRequiredProducts.push(product);
                } else {
                    this.nonPrescriptionRequiredProducts.push(product);
                }
            });

            if (outOfStockProductsList.length > 0) {
                console.log('outOfStockProductsList: ', outOfStockProductsList);
            }

            // this.prescriptionRequiredProducts = this.cartData.products.filter((product) => product.prescription_required == true);

            // this.nonPrescriptionRequiredProducts = this.cartData.products.filter((product) => product.prescription_required == false);
            this.skeletonLoaderFlag = false;
            this.callpermissionflag = true;
        }).catch((viewParticularCartError) => {
            console.log('Error fetching cart details: ', viewParticularCartError);

            if (viewParticularCartError.response.status == 401) {
                this.isUserLoggedIn = false;
            } else if (viewParticularCartError.response.status == 404) {
                this.skeletonLoaderFlag = false;
                this.emptyCartFlag = true;
            }

            // Throw error popup.
        })
    },
    beforeUnmount() {
		window.removeEventListener('resize', this.handleResize);
	},
    methods: {
        handleResize() {
			this.screenWidth = window.innerWidth;
			if (this.screenWidth <= 600) {
				
			} else {
				
			}
		},
        scrollIntoView(id) {
            let element = document.getElementById(id);

            element.scrollIntoView(true);
        },
        openFileInput() {
            this.$refs.fileInput.$el.querySelector('input[type="file"]').click();
        },
        uuidv4() {
            return 'xxxxxx#@#'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        handleFileUpload() {
            let currentFile = this.uploadedFile;

            let fileName = this.uuidv4() + currentFile.name.split(" ").join("_").toLowerCase();

            this.uploadedPrescriptions.push({
                file_name: fileName,
                file_url: '',
                file_type: currentFile.type,
                upload_status: 'IN_PROGRESS'
            });

            let currentFileIndex = this.uploadedPrescriptions.findIndex((file) => (file.file_name === fileName));
            if (currentFile.size >= 10485760) {
                this.uploadedPrescriptions[currentFileIndex].upload_status = 'TooLarge';
            }

            let presignedURLWriteRequest = {
                // token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcGVuSUQiOiJlMjVjM2E5ZGIxMzI3ZTFkNjFmYzE2MjU3NmIwNzJiNjI2ZmRhZDZiM2MwN2YzYzEyMzQ4MTBlODYxMmZiYTNkIiwidGltZXN0YW1wIjoiMjAyMy0wMy0wMVQwODoyMjo1Mi42ODhaIiwiaWF0IjoxNjc3NjU4OTcyfQ.NmRPiVLiaGlprTMySEoOcOyPBfDsWN3hep5uAwrYHFs',
                // typeOfUser: 'CUSTOMER',
                file_name: fileName,
                file_type: currentFile.type,
            };
        
            axios_auth_instance_epharmacy_customer.post("/cart/presignedURLWrite", presignedURLWriteRequest).then((presignedURLWriteResponse) => {
                let fields = presignedURLWriteResponse.data.data.fields;
                let url = presignedURLWriteResponse.data.data.url;
                let post_body = {
                    ...fields,
                    'Content-Type': currentFile.type,
                    file: currentFile
                };

                const formData = new FormData();
                for(const name in post_body) {
                    formData.append(name, post_body[name]);
                }

                axios.post(url, formData).then((imgUploadSuccess) => {
                    this.uploadedPrescriptions[currentFileIndex].upload_status = 'Completed';

                    let presignedURLReadRequest = {
                        // token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcGVuSUQiOiJlMjVjM2E5ZGIxMzI3ZTFkNjFmYzE2MjU3NmIwNzJiNjI2ZmRhZDZiM2MwN2YzYzEyMzQ4MTBlODYxMmZiYTNkIiwidGltZXN0YW1wIjoiMjAyMy0wMy0wMVQwODoyMjo1Mi42ODhaIiwiaWF0IjoxNjc3NjU4OTcyfQ.NmRPiVLiaGlprTMySEoOcOyPBfDsWN3hep5uAwrYHFs',
                        // typeOfUser: 'CUSTOMER',
                        file_name: fileName
                    };
                    
                    // Presigned URL Read.
                    axios_auth_instance_epharmacy_customer.get("/cart/presignedURLRead/", { params: presignedURLReadRequest }).then((presignedURLReadResponse) => {
                        this.uploadedPrescriptions[currentFileIndex].file_url = presignedURLReadResponse.data.data;
                    }).catch((presignedURLReadError) => {
                        console.log('presignedURLReadError: ', presignedURLReadError);
                    });
                }).catch((imgUploadError) => {
                    console.log('[ERROR]: Error in image upload - ', imgUploadError);
                });
            }).catch((getPresignedURLError) => {
                console.log('[ERROR]: Error in getPresignedURL - ', getPresignedURLError);
            });
        },
        removePrescription(index) {
            this.uploadedPrescriptions.splice(index, 1);
        },
        handleRemoveProduct(productId) {
            let productIndex = this.cartData.products.findIndex((product) => productId == product.product_id);
            this.cartData.products.splice(productIndex, 1);

            if (this.cartData.products.length == 0) {
                this.emptyCartFlag = true;
            }
        },
        addAddress() {
            this.addAddressClicked = true;

            // Add address API call.
            let createAddressRequest = {
                deliverTo: this.addAddressData.delivered_to_name,
                addressLine1: this.addAddressData.house_number,
                addressLine2: this.addAddressData.street_address,
                addressTypeName: this.selectedShippingAddressType,
                stateId: this.addAddressData.state.id,
                mobile: this.addAddressData.phone_number,
                landmark: this.addAddressData.landmark
            };

            axios_auth_instance_epharmacy_customer.post('/address', createAddressRequest).then((createAddressResponse) => {
                this.cartData.addresses.splice(this.cartData.addresses.length - 1, 0, {
                    address_unique_id: createAddressResponse.data.address_unique_id,
                    address_type_name: this.selectedShippingAddressType,
                    ...this.addAddressData
                });

                this.addAddressClicked = false;
            }).catch((createAddressError) => {
                console.log('Error adding address: ', createAddressError);
            });
        },
        editAddressPopupOpen(editAddressIndex) {
            this.editAddressData = this.cartData.addresses[editAddressIndex];

            // Fetch State Name.
            if (this.addressStateList == null || this.addressStateList == []) {
                this.editAddressPopupLoader = true;
                axios_auth_instance_epharmacy_customer.get('/address/stateCountryList').then((getStateCountryIdListResponse) =>{
                    this.addressStateList = getStateCountryIdListResponse.data.data.listOfState;
                    this.addressCountryList = getStateCountryIdListResponse.data.data.listOfCountry;

                    let stateIndex = this.addressStateList.findIndex((state) => String(state.id) == String(this.editAddressData.state_id));
                    this.editAddressData.state = this.addressStateList[stateIndex];
                    this.editAddressPopupLoader = false;
                    this.editAddressPopup = true;
                }).catch((getStateCountryIdListError) => {
                    console.log('Error fetch state & country list: ', getStateCountryIdListError);
                });
            } else {
                let stateIndex = this.addressStateList.findIndex((state) => String(state.id) == String(this.editAddressData.id));
                this.editAddress.state = this.addressStateList[stateIndex];
                this.editAddressPopup = true;
            }
        },
        editAddressPopupClose() {
            this.editAddressPopup = false;
            this.editAddressData = {};
        },
        editAddress() {
            this.editAddressClickedFlag = true;
            
            let updateAddressRequest = {
                deliverTo: this.editAddressData.delivered_to_name,
                addressLine1: this.editAddressData.house_number,
                addressLine2: this.editAddressData.street_address,
                addressTypeName: this.editAddressData.address_type_name,
                stateId: this.editAddressData.state_id,
                mobile: this.editAddressData.mobile_number,
                landmark: this.editAddressData.landmark
            };

            axios_auth_instance_epharmacy_customer.put('/address/' + this.editAddressData.address_unique_id, updateAddressRequest).then((updateAddressResponse) =>{
                let editAddressIndex = this.cartData.addresses.findIndex((address) => String(address.address_unique_id) == String(this.editAddressData.address_unique_id));
                this.cartData.addresses[editAddressIndex] = {
                    delivered_to_name: this.editAddressData.delivered_to_name,
                    street_address: this.editAddressData.street_address,
                    city: this.editAddressData.city,
                    address_unique_id: this.editAddressData.address_unique_id,
                    address_type_name: this.editAddressData.address_type_name,
                    house_number: this.editAddressData.house_number,
                    state_id: this.editAddressData.state_id,
                    mobile_number: this.editAddressData.mobile_number,
                    landmark: this.editAddressData.landmark
                };

                this.editAddressClickedFlag = false;
                this.editAddressPopup = false;
            }).catch((updateAddressError) => {
                console.log('Error editing address: ', updateAddressError);
                this.editAddressPopup = false;
            });
        },
        redirectTo(pageName, params) {
            this.$router.push({
                name: pageName
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/global.scss';
@import '../../../scss/classes.scss';

.empty-cart-actions-web, .navigation-tabs-segment, .cart-heading, .footer-segment, .shipping-address-web-component, .redirection-tags {
    @media (max-width: 600px) {
        display: none;
	}
}
.empty-cart-actions-mobile, .mobile-header-segment, .shipping-address-mobile-component, .shipping-address-mobile-component {
    @media (min-width: 601px) {
		display: none;
	}
}

.loader-web {
    @media (max-width: 1264px) {
        display: none;
	}
}

.loader-mobile {
    @media (min-width: 1265px) {
		display: none;
	}
}

.empty-cart-segment {
    @media (max-width: 600px) {
        height: 70vh;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		height: 70vh;
	}

	@media (min-width: 1265px) {

    }
}

.empty-cart-web-width {
    @media (min-width: 1265px) {
		max-width: 480px;
	}
}

.empty-cart-action-button {
    width: 200px;
}

.step-number {
    // line-height: 0px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.px-icon {
    width: 20px;
    height: 20px;
}

.main-segment-background {
    height: 100%;

    @media (max-width: 600px) {
        // padding: 16px;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		// padding: 16px;
	}

	@media (min-width: 1265px) {
		background-color: $light-grey-color;
        padding: 24px;
	}
}

.order-flow-segment {
    @media (max-width: 600px) {
        width: 100%;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		width: 100%;
	}

	@media (min-width: 1265px) {
		width: 60%;
        border-radius: 8px;
	}
}

.order-summary-segment {
    @media (max-width: 600px) {
        width: 100%;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		width: 100%;
	}

	@media (min-width: 1265px) {
		width: 40%;
	}
}

.upload-prescription-segment {
    width: 100%;
    @media (max-width: 600px) {

	}

	@media (min-width: 601px) and (max-width: 1264px) {
		
	}

	@media (min-width: 1265px) {
		
	}
}

.from-device-segment, .from-previous-consultation-segment {
    width: 48%;

    .button-class {
        width: 100%;
        letter-spacing: 0px;
    }
}

.upload-options-layout {
    h3 {
        height: 10vh;
    }

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;        

        h3, .upload-options-button {
            width: 100%;
            padding-top: 8px;
        }
	}

	@media (min-width: 601px) and (max-width: 1264px) {
        align-items: center;

		h3, .upload-options-button {
            padding-left: 8px;
        }
	}

	@media (min-width: 1265px) {
        align-items: center;

		h3, .upload-options-button {
            padding-left: 8px;
        }
	}
}

.address-list-section {
    height: 240px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.uploaded-prescriptions-container {
    width: 100%;
    // min-height: 25vh;
    gap: 16px;
}


.full-width-button, .address-radio-item {
    width: 100%;
}

.shipping-radio-item {
    width: 50%;
}

.footer-icon {
    width: 20px;
    height: 20px;
}

.dashed-border-line {
    @media (min-width: 1265px) {
		display: none;
	}
}
.half-width {
    width: 50%;
}

.one-third-width {
    width: 33%;
}
.prescription-image-container {
    position: relative;
}

.prescription-thumbnail {
    @media (max-width: 600px) {
        width: 100px;
        height: 120px;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        width: 100px;
        height: 120px;
    }

    @media (min-width: 1265px) {
        width: 100px;
        height: 120px;
    }
}

.prescription-remove-icon {
    position: absolute;
    top: -12px;
    right: -12px;
    cursor: pointer;
    z-index: 999;
}

.thumbnail-close-icon {
    @media (max-width: 600px) {
        width: 24px;
        height: 24px;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        width: 24px;
        height: 24px;
    }

    @media (min-width: 1265px) {
        width: 24px;
        height: 24px;
    }
}

.mobile-bottom-nav-bar {
	@media (max-width: 600px) {
        
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		display: none !important;
	}

	@media (min-width: 1265px) {
		display: none !important;
	}
}
.edit-address-action-button {
    min-width: 200px;
}
</style>

<style lang="scss">
@import '../../../scss/global.scss';
@import '../../../scss/classes.scss';

.v-input__slot::before, .v-input__slot::after {
    display: none;
    margin-bottom: 0px !important;
}

.state-segment.v-text-field.v-text-field--enclosed .v-text-field__details {
    display: none;
}
.state-segment .v-input__slot {
    margin-bottom: 0px !important;
}

.state-segment input {
    @media (max-width: 600px) {
        font-size: 12px !important;
	}
}
</style>